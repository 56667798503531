<template>
  <el-container style="height: 100%">
    <el-aside width="200px" style="border-right: solid 1px #e6e6e6">
      <el-menu style="border: none">
        <el-menu-item
          v-for="route in routes"
          :key="route.path"
          @click="$router.push(route.path)"
          >{{ route.children[0].meta.title }}</el-menu-item>
      </el-menu>
    </el-aside>

    <el-container>
      <el-main>
        <router-view />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
export default {
  computed: {
    routes () {
      return this.$router.options.routes.filter(
        (item) => item.children && item.children[0]
      )
    }
  }
}
</script>

<style>
.el-aside {
  color: #333;
}
</style>
