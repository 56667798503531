import Vue from 'vue'
import {
  Button, Table, TableColumn, Dialog, MessageBox,
  Form, FormItem, Input, Loading, Option, Select,
  Message, DatePicker, Checkbox, Col, Row, Pagination,
  Menu, MenuItem, Container, Main, Aside
} from 'element-ui'

Vue.use(Button)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Dialog)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Loading)
Vue.use(Select)
Vue.use(Option)
Vue.use(DatePicker)
Vue.use(Checkbox)
Vue.use(Col)
Vue.use(Row)
Vue.use(Pagination)
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(Container)
Vue.use(Main)
Vue.use(Aside)
Vue.prototype.$message = Message

// Vue.prototype.$loading = Loading.service
// Vue.prototype.$msgbox = MessageBox
// Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$confirm = MessageBox.confirm
// Vue.prototype.$prompt = MessageBox.prompt
// Vue.prototype.$notify = Notification
