import Vue from 'vue'
import VueRouter from 'vue-router'
import CodeIncome from '@/views/code-income'
import Goods from '@/views/goods'
import Layout from '@/components/layout'
import Projects from '@/views/projects'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/code-income'
  },
  {
    path: '/code-income',
    component: Layout,
    children: [{
      path: '',
      name: 'CodeIncome',
      component: CodeIncome,
      meta: { title: '淘宝订单', icon: 'dashboard' }
    }]
  },
  {
    path: '/projects',
    component: Layout,
    children: [{
      path: '',
      name: 'Projects',
      component: Projects,
      meta: { title: '项目管理', icon: 'dashboard' }
    }]
  },
  {
    path: '/goods',
    component: Layout,
    children: [{
      path: '',
      name: 'Goods',
      component: Goods,
      meta: { title: '商品管理', icon: 'dashboard' }
    }]
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
