import axios from 'axios'
import { Message } from 'element-ui'

const service = axios.create({
  baseURL: 'https://code.fashengwang.com/api',
  // baseURL: 'http://localhost:7001/api',
  timeout: 5000
})

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'

service.interceptors.request.use(
  config => {
    config.headers['x-token'] = 'K1JOkJQDL4jZd2mWQ8A116DbNn8VhPUt'
    config.method = config.method || 'GET'
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  async response => {
    const res = response.data
    if (res.code !== 200) {
      Message({
        message: res.message || 'Error',
        type: 'error',
        duration: 5 * 1000
      })
    }
    return res
  },
  error => {
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
