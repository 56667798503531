<template>
  <div class="c-card">
    <div class="cover" :style="{ backgroundImage: `url(${data.cover})` }"></div>
    <div class="info">
      <div class="text-ellipsis">名称: {{ data.name }}</div>
      <div class="text-ellipsis">标签: {{ data.tags.join('、') }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>

<style lang="scss" scoped>
.c-card {
  width: 260px;
  border-radius: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 8px 8px 0;

  &:hover {
    .cover {
      transform: scale(1.1);
    }
  }

  .cover {
    height: 200px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    transform-origin: center;
    transform: scale(1);
    transition: transform 0.15s ease-out;
  }

  .info {
    padding: 6px;
  }
}
</style>
