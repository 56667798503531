<template>
  <div class="app-container">
    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button
          type="primary"
          plain
          icon="el-icon-plus"
          size="mini"
          @click="handleAdd"
          >新增</el-button
        >
      </el-col>
      <el-col :span="1.5">
        <el-input v-model="queryParams.id" size="mini" placeholder="ID" @keyup.enter.native="getList(1)"></el-input>
      </el-col>
      <el-col :span="1.5">
        <el-input v-model="queryParams.name" size="mini" placeholder="name" @keyup.enter.native="getList(1)"></el-input>
      </el-col>
      <el-col :span="1.5">
        <el-select v-model="queryParams.status"
          size="mini"
          @change="getList(1)">
          <el-option :value="1" label="上架中">上架中</el-option>
          <el-option :value="0" label="已下架">已下架</el-option>
        </el-select>
      </el-col>
    </el-row>

    <el-table v-loading="loading" :data="goodsList" stripe>
      <el-table-column label="ID" align="center" prop="id" />
      <el-table-column label="页面名称" align="center" prop="html" />
      <el-table-column label="商品名称" align="center" prop="name" />
      <el-table-column label="图片" align="center" prop="imageList" width="80">
        <template slot-scope="{ row }">
          <el-button size="mini" type="text">查看({{ row.imageList.length }})</el-button>
        </template>
      </el-table-column>
      <el-table-column label="技术" align="center">
        <template slot-scope="{ row }">{{ row.goodsType.map(item => item.name).join('+') }}</template>
      </el-table-column>
      <el-table-column label="价格" align="center" prop="price" width="80">
        <template slot-scope="{ row }">￥{{ row.price }}</template>
      </el-table-column>
      <el-table-column label="储存地址" align="center" prop="address" />
      <el-table-column label="状态" align="center" prop="status" width="100">
        <template slot-scope="{ row }">{{ row.status == 1 ? '上架中' : '已下架' }}</template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        class-name="small-padding fixed-width"
      >
        <template slot-scope="{ row }">
          <el-button v-if="row.status == 1" size="mini" type="text">下架</el-button>
          <el-button v-else size="mini" type="text">上架</el-button>
          <el-button v-if="row.status == 1" size="mini" type="text" @click="toView(row.html)">查看</el-button>
          <el-button
            size="mini"
            type="text"
            @click="handleUpdate(row)"
            >修改</el-button
          >
          <el-button
            size="mini"
            type="text"
            style="color: #f56c6c"
            @click="handleDelete(row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="queryParams.page"
      :limit.sync="queryParams.pageSize"
      @pagination="getList()"
    />

    <!-- 添加或修改goods对话框 -->
    <el-dialog :title="title" :visible.sync="open" append-to-body>
      <el-form ref="form" :model="form" label-width="80px">
        <div style="display: flex">
          <el-form-item label="页面名称" prop="html">
            <el-input v-model="form.html" placeholder="请输入页面名称" />
          </el-form-item>
          <el-form-item label="商品名称" prop="name">
            <el-input v-model="form.name" style="width: 300px" placeholder="请输入商品名称" />
          </el-form-item>
        </div>
        <el-form-item label="描述" prop="goodsDesc">
          <el-input v-model="form.goodsDesc" type="textarea" rows="6" placeholder="请输入描述" />
        </el-form-item>
        <el-form-item label="图片" prop="imageText">
          <el-input v-model="form.imageText" type="textarea" rows="6" placeholder="请输入图片" />
        </el-form-item>
        <div style="display: flex">
          <el-form-item label="价格" prop="price">
            <el-input v-model="form.price" placeholder="请输入价格" />
          </el-form-item>
          <el-form-item label="储存地址" prop="address">
            <el-input v-model="form.address" placeholder="请输入储存地址" />
          </el-form-item>
          <el-form-item label="状态" prop="status">
            <el-select v-model="form.status">
              <el-option :value="1" label="上架中">上架中</el-option>
              <el-option :value="0" label="已下架">已下架</el-option>
            </el-select>
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { listGoods, delGoods, addGoods, updateGoods } from '@/api/goods'

export default {
  name: 'Goods',
  data () {
    return {
      // 遮罩层
      loading: true,
      // 总条数
      total: 0,
      // goods表格数据
      goodsList: [],
      // 弹出层标题
      title: '',
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        page: 1,
        pageSize: 10,
        status: 1,
        id: '',
        name: ''
      },
      // 表单参数
      form: {}
    }
  },
  created () {
    this.getList()
  },
  methods: {
    toView (name) {
      window.open(`https://www.fashengwang.com/goods-info/${name}.html`, '_blank')
    },
    /** 查询goods列表 */
    getList (page) {
      this.queryParams.page = page || this.queryParams.page
      this.loading = true
      listGoods(this.queryParams).then((response) => {
        this.goodsList = (response.data.list || []).map(item => {
          return {
            ...item,
            imageText: item.imageList.join('\n')
          }
        })
        this.total = response.data.count
        this.loading = false
      })
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.form = {}
    },
    /** 新增按钮操作 */
    handleAdd () {
      this.reset()
      this.open = true
      this.title = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row) {
      this.reset()
      this.form = JSON.parse(JSON.stringify(row))
      this.open = true
      this.title = '修改'
    },
    /** 提交按钮 */
    submitForm () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.form.imageList = this.form.imageText.split('\n')
          const func = this.form.id != null ? updateGoods : addGoods
          func(this.form).then((response) => {
            if (response.code === 200) {
              this.$message.success('新增成功')
              this.open = false
              this.getList()
            }
          })
        }
      })
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      const ids = row.id
      this.$confirm('是否确认删除数据项?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(function () {
          return delGoods(ids)
        })
        .then(() => {
          this.getList()
          this.$message.success('删除成功')
        })
        .catch(() => {})
    }
  }
}
</script>
