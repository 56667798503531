import request from '@/utils/request'

export const listGoods = (params) => {
  return request({
    url: '/admin/goods',
    params
  })
}

export const addGoods = (data) => {
  return request({
    method: 'POST',
    url: '/admin/goods',
    data
  })
}

export const updateGoods = (data) => {
  return request({
    method: 'PUT',
    url: `/admin/goods/${data.id}`,
    data
  })
}

export const delGoods = (id) => {
  return request({
    method: 'DELETE',
    url: `/admin/goods/${id}`
  })
}
