<template>
  <div class="app-container p-projects">
    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button
          type="primary"
          plain
          icon="el-icon-plus"
          size="mini"
          @click="handleAdd"
          >新增</el-button
        >
      </el-col>
    </el-row>

    <template v-if="mode == 'table'">
      <el-table v-loading="loading" :data="projectsList" stripe>
        <el-table-column label="ID" align="center" prop="id" />
        <el-table-column label="项目名称" align="center" prop="name" />
        <el-table-column label="封面地址" align="center" prop="cover">
          <template slot-scope="{ row }">
            <img :src="row.cover" style="" alt="" />
          </template>
        </el-table-column>
        <el-table-column label="演示视频地址" align="center" prop="video" />
        <el-table-column label="技术标签" align="center" prop="tags" />
        <el-table-column label="备注" align="center" prop="proDesc" />
        <el-table-column label="文件地址" align="center" prop="address" />
        <el-table-column
          label="操作"
          align="center"
          class-name="small-padding fixed-width"
        >
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="handleUpdate(scope.row)"
              >修改</el-button
            >
            <el-button
              size="mini"
              type="text"
              icon="el-icon-download"
              @click="handleDownload(scope.row)"
              >下载</el-button
            >
            <el-button
              size="mini"
              type="text"
              icon="el-icon-delete"
              @click="handleDelete(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </template>
    <template v-else>
      <div class="card-list">
        <ProCard
          v-for="pro in projectsList"
          :data="pro"
          :key="pro.id"
          @click.native="handlePreview(pro)"
        ></ProCard>
      </div>
    </template>

    <!-- 添加或修改projects对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="500px" append-to-body>
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="项目名称" prop="name">
          <el-input v-model="form.name" placeholder="请输入项目名称" />
        </el-form-item>
        <el-form-item label="封面地址" prop="cover">
          <el-input v-model="form.cover" placeholder="请输入封面地址" />
        </el-form-item>
        <el-form-item label="演示视频地址" prop="video">
          <el-input v-model="form.video" placeholder="请输入演示视频地址" />
        </el-form-item>
        <el-form-item label="技术标签" prop="tags">
          <el-input v-model="form.tags" placeholder="请输入技术标签" />
        </el-form-item>
        <el-form-item label="备注" prop="proDesc">
          <el-input v-model="form.proDesc" placeholder="请输入备注" />
        </el-form-item>
        <el-form-item label="文件地址" prop="address">
          <el-input v-model="form.address" placeholder="请输入文件地址" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="预览"
      :visible.sync="previewVisible"
      width="800px"
      append-to-body
    >
      <div v-if="currentData" class="preview-content">
        <video :src="currentData.video"></video>
        <div class="right">
          <div>名称: {{ currentData.name }}</div>
          <div>标签: {{ currentData.tags.join("、") }}</div>
          <div>备注: {{ currentData.proDesc }}</div>
          <el-button
            type="text"
            icon="el-icon-download"
            @click="handleDownload(currentData)"
            >下载</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  listProjects,
  delProjects,
  addProjects,
  updateProjects
} from '@/api/projects'
import ProCard from './components/card.vue'

export default {
  name: 'Projects',
  components: { ProCard },
  data () {
    return {
      // 遮罩层
      loading: true,
      // 总条数
      total: 0,
      // projects表格数据
      projectsList: [],
      // 弹出层标题
      title: '',
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        page: 1,
        pageSize: 99999
      },
      // 表单参数
      form: {},
      previewVisible: false,
      currentData: null,
      mode: 'table'
    }
  },
  created () {
    this.getList()
  },
  methods: {
    /** 查询projects列表 */
    getList () {
      this.loading = true
      listProjects(this.queryParams).then((response) => {
        this.projectsList = response.data.list
        this.total = response.data.count
        this.loading = false
      })
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.form = {}
    },
    /** 新增按钮操作 */
    handleAdd () {
      this.reset()
      this.open = true
      this.title = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row) {
      this.reset()
      this.form = JSON.parse(JSON.stringify(row))
      this.open = true
      this.title = '修改'
    },
    /** 提交按钮 */
    submitForm () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const func = this.form.id != null ? updateProjects : addProjects
          func(this.form).then((response) => {
            this.form.id != null
              ? this.$message.success('修改成功')
              : this.$message.success('新增成功')
            this.open = false
            this.getList()
          })
        }
      })
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      const ids = row.id
      this.$confirm('是否确认删除数据项?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(function () {
          return delProjects(ids)
        })
        .then(() => {
          this.getList()
          this.$message.success('删除成功')
        })
        .catch(() => {})
    },
    handlePreview (pro) {
      this.currentData = pro
      this.previewVisible = true
    },
    handleDownload () {}
  }
}
</script>

<style lang="scss">
.preview-content {
  display: flex;
  height: 400px;
  video {
    width: 600px;
    height: 400px;
  }
  .right {
    flex: 1;
  }
}
</style>

<style lang="scss" scoped>
.p-projects {
  .card-list {
    display: flex;
    flex-wrap: wrap;
  }
}
</style>
