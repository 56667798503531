<template>
  <div class="app-container">
    <div class="filter">
      <el-select v-model="year" size="mini" placeholder="请选择年份" @change="changeYear">
        <el-option
          v-for="item in yearList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-input v-model="searchText" style="width: 160px" size="mini" placeholder="搜索"></el-input>
      <el-button size="mini" type="primary" @click="beforeCreate">新建</el-button>
      <span>总金额：{{ formatNumber(priceCount) }}</span>
      <span>已成交：{{ formatNumber(dealCount) }}</span>
    </div>

    <div>
      <span v-for="(key, index) in Object.keys(dateCountList)" :key="index">{{ key }}: {{ formatNumber(dateCountList[key]) }} | </span>
    </div>
    <div class="income-charts"></div>

    <div>
      <span>共{{ searchList.length }}条</span>
      <span v-if="selectedCount">已选总金额：{{ formatNumber(selectedCount) }}</span>
    </div>

    <el-table
      v-loading="loading"
      :data="searchList"
      :row-class-name="tableRowClassName"
      height="85vh"
      size="mini"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <!-- <el-table-column label="ID" align="center" prop="id" /> -->
      <el-table-column label="名称" align="center" prop="name" />
      <el-table-column label="预算" align="center" prop="price" />
      <el-table-column label="创建时间" align="center" prop="createdAt" />
      <el-table-column
        label="截止时间"
        align="center"
        sortable
        prop="deadlineShow"
      />
      <el-table-column label="付款方式" align="center" prop="type" />
      <el-table-column label="技术" align="center" prop="skill" />
      <el-table-column label="备注详情" align="center" prop="detail">
        <template slot-scope="{ row }">{{ row.detail || "-" }}</template>
      </el-table-column>
      <el-table-column
        label="状态"
        align="center"
        prop="statusShow"
        :filters="statusOptions"
        :filter-method="filterStatus"
        :filtered-value="[1, 2, 3, 4]"
      />
      <el-table-column label="操作" align="center" width="160">
        <template slot-scope="{ row }">
          <el-button
            size="mini"
            type="text"
            icon="el-icon-edit"
            @click="beforeUpdate(row)"
            >修改</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-dialog title="创建/修改" :visible.sync="visibleDialog" width="600px">
      <el-form inline label-width="80px" size="800px">
        <el-form-item label="名称">
          <el-input v-model="editRow.name"></el-input>
        </el-form-item>

        <el-form-item label="预算">
          <el-input v-model="editRow.price"></el-input>
        </el-form-item>

        <el-form-item label="截止时间">
          <el-date-picker
            v-model="editRow.deadline"
            type="date"
            placeholder="选择截止时间"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="类型">
          <el-input v-model="editRow.type"></el-input>
        </el-form-item>

        <el-form-item label="技术">
          <el-input v-model="editRow.skill"></el-input>
        </el-form-item>

        <el-form-item label="备注详情">
          <el-input v-model="editRow.detail"></el-input>
        </el-form-item>

        <el-form-item label="状态">
          <el-select v-model="editRow.status" placeholder="请选择状态">
            <el-option
              v-for="item in statusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-button type="primary" size="mini" @click="handleSave">保存</el-button>
    </el-dialog>
  </div>
</template>

<script>
import { listCodeIncome, addCodeIncome, updateCodeIncome } from '@/api/code-income'
import * as echarts from 'echarts/core'
import { TooltipComponent, GridComponent } from 'echarts/components'
import { BarChart } from 'echarts/charts'
import { CanvasRenderer } from 'echarts/renderers'
import { formatNumber } from '@/utils/index'
echarts.use([TooltipComponent, GridComponent, BarChart, CanvasRenderer])

export default {
  data () {
    return {
      dataList: [],
      loading: false,
      editRow: {},
      visibleDialog: false,
      editOrCreate: '',
      statusOptions: [
        { label: '未开始', text: '未开始', value: 1 },
        { label: '进行中', text: '进行中', value: 2 },
        { label: '已完成未交付', text: '已完成未交付', value: 3 },
        { label: '已交付未付款', text: '已交付未付款', value: 4 },
        { label: '已完成', text: '已完成', value: 5 },
        { label: '合作终止已关闭', text: '合作终止已关闭', value: 6 }
      ],
      year: new Date().getFullYear(),
      yearList: [],
      priceCount: 0,
      dealCount: 0,
      dateCountList: {},
      selectedCount: 0,
      searchText: ''
    }
  },
  created () {
    this.yearList = Array(this.year - 2019).fill(1).map((_, i) => {
      return { label: this.year - i, value: this.year - i }
    })
    this.fetchDataList()
  },
  computed: {
    searchList () {
      if (this.searchText) {
        return this.dataList.filter(item => {
          return item.name.toLowerCase().includes(this.searchText.toLowerCase())
        })
      } else {
        return this.dataList
      }
    }
  },
  methods: {
    formatNumber,
    filterStatus (value, row) {
      return row.status === value
    },
    tableRowClassName ({ row }) {
      if (row.status === 5) {
        return 'success-row'
      } else if (row.status === 4) {
        return 'warning-row'
      } else if (row.status === 6) {
        return 'error-row'
      }
      return ''
    },
    async fetchDataList () {
      const res = await listCodeIncome()
      if (res.code === 200) {
        const list = res.data.list.filter(item => {
          const dl = +new Date(item.deadline)
          return dl >= +new Date(`${this.year}-01-01 00:00:00`) && dl < +new Date(`${this.year + 1}-01-01 00:00:00`)
        })

        this.priceCount = 0
        this.dealCount = 0
        this.dataList = list.map((item) => {
          if (item.status !== 6) {
            this.priceCount += item.price
          }
          if (item.status === 5) {
            this.dealCount += item.price
          }
          return {
            ...item,
            deadlineShow: item.deadline.split(' ')[0],
            statusShow: this.statusOptions.filter(
              ({ value }) => value === item.status
            )[0].label
          }
        })
        this.dateCountList = {}
        this.dataList.filter(({ status }) => status === 5).forEach(item => {
          const date = item.deadline.split(' ')[0]
          const arr = date.split('-')
          const yymm = `${arr[0]}-${arr[1]}`
          this.dateCountList[yymm] = this.dateCountList[yymm] || 0
          this.dateCountList[yymm] += item.price
        })
        this.renderIncomeCharts()
      }
    },
    changeYear () {
      this.fetchDataList()
    },
    beforeUpdate (row) {
      this.editRow = JSON.parse(JSON.stringify(row))
      this.editOrCreate = 'edit'
      this.visibleDialog = true
    },
    async handleUpdate () {
      const res = await updateCodeIncome(this.editRow.id, {
        ...this.editRow,
        deadline: +new Date(this.editRow.deadline) + 1
      })
      this.fetchDataList()
      res.code === 200
        ? this.$message.success('修改成功')
        : this.$message.error('修改失败')
      this.editRow = {}
      this.editOrCreate = ''
      this.visibleDialog = false
    },
    beforeCreate () {
      this.editOrCreate = 'create'
      this.editRow = {}
      this.visibleDialog = true
    },
    async handleCreate () {
      const res = await addCodeIncome({
        ...this.editRow,
        deadline: +new Date(this.editRow.deadline) + 1
      })
      this.fetchDataList()
      res.code === 200
        ? this.$message.success('创建成功')
        : this.$message.error('创建失败')
      this.editOrCreate = ''
      this.visibleDialog = false
    },
    handleSave () {
      this.editOrCreate === 'edit' ? this.handleUpdate() : this.handleCreate()
    },
    handleSelectionChange (val) {
      this.selectedCount = 0

      val.forEach(item => {
        this.selectedCount += item.price
      })
    },
    renderIncomeCharts () {
      var chartDom = document.querySelector('.income-charts')
      var myChart = echarts.init(chartDom)
      const keys = Object.keys(this.dateCountList)
      const values = Object.values(this.dateCountList)
      myChart.setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        xAxis: {
          type: 'category',
          data: keys.reverse()
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: values.reverse(),
            type: 'bar'
          }
        ]
      })
    }
  }
}
</script>

<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
.el-table .error-row {
  background: #F56C6C;
}
</style>

<style lang="scss" scoped>
.app-container {
  padding: 16px;
  .filter {
    display: flex;
    align-items: center;
    margin: 0 0 8px;
    * {
      margin: 0 16px 0 0;
    }
  }
  .income-charts {
    width: 1200px;
    height: 400px;
  }
}
</style>
