import request from '@/utils/request'

// 查询projects列表
export function listProjects ({ page, pageSize }) {
  return request({
    url: '/admin/projects',
    method: 'get',
    params: { page, pageSize }
  })
}

export function updateProjects (data) {
  return request({
    url: `/admin/projects/${data.id}`,
    method: 'PUT',
    data
  })
}
export function addProjects (data) {
  return request({
    url: '/admin/projects',
    method: 'POST',
    data
  })
}

export function delProjects (id) {
  return request({
    url: `/admin/projects/${id}`,
    method: 'DELETE'
  })
}
