import request from '@/utils/request'

export const listCodeIncome = () => {
  return request({
    url: '/admin/code-income?pageSize=99999'
  })
}

export const addCodeIncome = (data) => {
  return request({
    method: 'POST',
    url: '/admin/code-income',
    data
  })
}

export const updateCodeIncome = (id, data) => {
  return request({
    method: 'PUT',
    url: `/admin/code-income/${id}`,
    data
  })
}
